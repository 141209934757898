export const useMenuItems = [
    {
        id: 1,
        label: '업무관리',
        isTitle: true
    },
    {
        id: 12,
        label: 'PUSH 관리',
        icon: 'mail',
        subItems: [
            {
                id: 121,
                label: "PUSH 알림 보내기",
                link: '/push/msg',
                parentId: 12
            },
            // {
            //     id: 123,
            //     label: "수신자 관리",
            //     link: '/push/member',
            //     parentId: 12
            // },
            {
                id: 122,
                label: "PUSH 발송내역",
                link: '/push/history',
                parentId: 12
            }
        ]
    },
    {
        id: 102,
        label: '이벤트 관리',
        icon: 'image',
        link: '/popverticalflyers'
    },
    {
        id: 2,
        label: '메뉴 관리',
        icon: 'trello',
        link: '/'
    },
    {
        id: 21,
        label: '메뉴 카테고리 관리',
        icon: 'grid',
        link: '/product/category'
    },
    {
        id: 13,
        label: 'Q&A',
        icon: 'list',
        link: '/board/qa'
    },
    {
        id: 11,
        label: 'MY정보',
        icon: 'user',
        link: '/auth/myinfo'
    },
    {
        id: 101,
        label: '가이드',
        icon: 'play-circle',
        link: '/guide'
    },
    {
        id: 111,
        label: 'test',
        icon: 'user',
        link: '/voc'
    },
];

