<script>
//import simplebar from "simplebar-vue";

// import i18n from "../i18n";

import {mapState} from "vuex";
import {loggedInfoMethods, loggedInfoObject} from "@/state/helpers";

/**
 * Topbar component
 */
export default {
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
      ],
      // lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      martName: '',
    }
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    layoutscroll: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    // this.value = this.languages.find((x) => x.language === i18n.locale);
    // this.text = this.value.title;
    // this.flag = this.value.flag;

    this.martName = this.logged_info.mart_name
  },
  methods: {
    ...loggedInfoMethods,
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    logout() {
      console.log('로그아웃')
      this.setInitInfo()
      console.log(this.logged_info)

      this.$router.push(
          this.$route.query.redirectFrom || {
            name: "login",
          }
          //'../product-board'
      ).catch(error => {
        console.info(error.message)
      })
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
  },
  components: {
    //simplebar,
  },
  computed: {
    ...mapState(loggedInfoObject)
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    layoutscroll: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case false:
              document.body.setAttribute("data-layout-scrollable", "false");
              break;
            case true:
              document.body.setAttribute("data-layout-scrollable", "true");
              break;
            default:
              document.body.setAttribute("data-layout-scrollable", "false");
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <div class="navbar-header" style="padding-left: 10px;">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <router-link to="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="@/assets/mcimages/slogo.png" alt="" height="36"/>
<!--            <img src="@/assets/mcimages/Icon-80.png" alt="" height="36"/>-->
          </span>
          <span class="logo-lg">
            <img src="@/assets/mcimages/critalklogo.png" alt="" height="38"/>
<!--            <img src="@/assets/mcimages/logo@3x.png" alt="" height="38"/>-->
          </span>
        </router-link>
      </div>

      <button
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
          @click="toggleMenu"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>


      <label
          class="d-none d-lg-block m-0"
          style="font-size: 14.4px;
          font-weight: 600;
          display: block;
          color: #495057;"
      >
      {{ martName }}
      </label>
    </div>

    <div class="d-flex">

      <b-button
          variant="white"
          style="font-size: 14.4px;
          font-weight: 600;
          display: block;
          color: #495057;"
          @click="logout()"
      >
        <i class="icon fa fa-power-off" style="vertical-align: bottom; font-size: 1.1rem"></i>
        로그아웃
      </b-button>
    </div>
  </div>
</template>
